import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import  "./svg.css";
import ReadMoreReact from 'read-more-react';

export default function Slider2() {
return (
    <div style={{ display: 'block' }}>
    <Carousel>
        <Carousel.Item >
        <a href="https://www.youtube.com/embed/GVAgFWBs8ZE" target="_blank" rel="noreferrer">
        <iframe className="text-center" width="866" height="487" src="https://www.youtube.com/embed/GVAgFWBs8ZE" title="எங்கயும் இந்த SURGERY பண்ணமுடியாது சொல்லிட்டாங்க! | Unveiling the Dark Secrets of Pulmonary Tamil 1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </a>
        
        </Carousel.Item>
        <Carousel.Item >
        <a href="https://www.youtube.com/embed/sFlok_Ajt1E" target="_blank" rel="noreferrer">
        <iframe width="866" height="487" src="https://www.youtube.com/embed/sFlok_Ajt1E" title="வாழ்க்கையை இப்படியே ஓட்டலாம் | Happy Dialysis Patient" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </a>
        </Carousel.Item>
        <Carousel.Item >
        <a href="https://www.youtube.com/embed/LVpKUBU7-ZQ" target="_blank" rel="noreferrer">
        <iframe width="866" height="487" src="https://www.youtube.com/embed/LVpKUBU7-ZQ" title="இருதயத்தில் செயற்கை வால்வு மதுரை மருத்துவமனையில் நவீன அறுவை சிகிச்சை | Devaki Heart Care Centre" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </a>
        </Carousel.Item>
        <Carousel.Item >
        <a href="https://www.youtube.com/embed/QueJAO-0x-U" target="_blank" rel="noreferrer">
        <iframe width="866" height="487" src="https://www.youtube.com/embed/QueJAO-0x-U" title="அறுவை சிகிச்சை இல்லாமல் இதய வால்வு மாற்று சிகிச்சை | TAVR by Dr. Rufus @devakispecialityhospital" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </a>
        </Carousel.Item>
        <Carousel.Item >
        <a href="https://www.youtube.com/embed/Mztp4zxwiKc" target="_blank" rel="noreferrer">
        <iframe width="866" height="487" src="https://www.youtube.com/embed/Mztp4zxwiKc" title="7 வருட வயிற்று வலி தொந்தரவு 2.30 மணி நேரத்தில் தீர்வு | Devaki Speciality Hospital | Dr.Praveen" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </a>
        </Carousel.Item>
        <Carousel.Item >
        <a href="https://www.youtube.com/embed/L1V-0jRcy2M" target="_blank" rel="noreferrer">
        <iframe width="866" height="487" src="https://www.youtube.com/embed/L1V-0jRcy2M" title="புது டெக்னிக்ல Heart ஆபரேஷன் | Mics by Dr. Reenus Demel | Devaki Speciality Hospital" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </a>
        </Carousel.Item>
    </Carousel>
    </div>
);
}

                        