import  React, {useState, useEffect}from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useForm } from "react-hook-form";
import axios from "axios";
// import BasicModalapi from './myapi';
// import BasicModalapi1 from './modalapi';
import Dropdown from 'react-dropdown';
import Select from 'react-select';
import ReactDOM from 'react-dom/client';
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',  
  transform: 'translate(-50%, -50%)',
  width: 800,
  height:600,
  bgcolor: 'background.paper',
  border: '2px',
  //overflow:scrollable;
  maxWidth: '100%',
    maxHeight: '100%',
    overflowY: 'scroll'
};

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showDetails, setShowDetails] = React.useState(true);
  const [selected, setSelected] = useState('yes');
  const [patient_type, setPatientType] = useState('new');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [result, setResult] = useState(null);
  const [doctorList, setDoctorlist] =useState([]);
  const [consulHr, setConsulHr] = useState ([]);
  const [date, setDate] = useState(new Date());
  const { format } = require("date-fns");
  // const [dayOfWeek ,setDayOfWeek] = useState(new Date());
  const [testVar, setTestVar] = useState ("");
  // const [phNo, setPhNo ]= useState('');
  const [weekOff, setWeekOff] = useState([]);
  const [selectedTime , setSelectedTime] = useState(null);
  const [timeError, setTimeError] = useState("");
  const [timeEmpty, setTimeEmpty] = useState("");
  const [firstName, setFirstName] = useState('') ;
  const [error, setError] = useState("");
  const [number, setNumber] = useState("");
  const [numError, setNumError] = useState("");
  const [docId ,setDocId] = useState("");
  const [outsideDr , setOutsideDr] = useState("");
  const [formatDate , setFormatDate] = useState("");
  const [formatDay , setFormatDay] = useState(""); 
  const [selectchange ,setSelectedChange] = useState("");
  const [docError, setDocError] = useState("");
  const [post, setPost] = useState(null);
  const [dateTime, setDateTime] = useState("");
  const [message, setMessage] = useState(null);
  const [preDate, setPreDate] = useState(new Date());
  const [patientId, setPatientId] = useState("");
  const [pError, setPError] = useState("");
  const [patId, setPatId] = useState("");

  useEffect(() => {
        getDoctersApi();
        console.log("useEffect...");
        console.log(date);
        console.log(date.getDate());
        {date.toLocaleDateString('de-DE')}
        // {date.toLocaleString("en", { weekday: "long" })}
        console.log(date);
        // setPreDate(date.getDate() + 1); 
      // var getConsultingHours = [] ;
      // getConsultingHours.push({
      //   hours: "Monday (09:00-09:30)"
      // })
      // getConsultingHours.push({
      //   hours: "Monday (09:30-10:00)"
      // })
      // getConsultingHours.push({
      //   hours: "Monday (10:00-10:30)"
      // })
      // console.log(getConsultingHours)
      // setConsulHr (getConsultingHours)
      // {getConsultingHours.map(item => {
      //     return <li>{item[0]}</li>;
      //   })}
    },[]);
    const getDoctersApi = () => {
      var baseUrl = 'https://care.devakicare.com/admin/api_controller/getconsultant?branch=1&api=1&online_appointment=1';
      axios.get(baseUrl,{}).then((response) => {
        var doctors = response.data.data
        const options = doctors.map(d => ({
          "value" : d.id,
          "label" : d.name,
          "otherStaff" : d.otherstaff,
          "consulHr" : d.consultation_hours,
          "phNo" : d.phone_number,
          "weekOff" : d.weeklyoff,
        }))
        console.log(options)
        console.log(consulHr) 
        setDoctorlist (options) 
      });
    };
    const doctoronChange = (option) =>{
    var doctorId = option.value
    setDocId(doctorId)
    var otherDoctor = option.otherStaff
    setOutsideDr(otherDoctor)
    var formatDate =(format ( date, 'yyyy-MM-dd'));
    if (docId == null){
      setDocError("❌ Please Select a doctor");
    }else{
      setDocError("");
    }
    getConsultingHrs(doctorId , otherDoctor , formatDate);
  } 
  const changeNextDate = (event, option) =>{
    const tomorrow = new Date(date);
    tomorrow.setDate(tomorrow.getDate() + 1);
    setDate(tomorrow) 
    var formatDate =(format ( date, 'yyyy-MM-dd'));
    setFormatDate(formatDate)
    var formatDay =format(date, 'EEEE');
    setFormatDay(formatDay)
    console.log(tomorrow);
    getConsultingHrs(docId , outsideDr , formatDate);
    // const dayOfWeek = format(date, 'EEEE');
    const dayOfWeek = format(tomorrow, 'EEEE');
    // const dayOfWeek = format(tomorrow, 'EEEE');
    // setDayOfWeek(format(date, 'EEEE'));
    console.log(dayOfWeek);
    if (formatDay && dayOfWeek === weekOff) {
    setTimeEmpty("❌ It's a Week Off");
  } else {
    setTimeEmpty("");
  }
    
  }
  const changePreDate = (event) =>{
    const yesterday = new Date(date);
    yesterday.setDate(yesterday.getDate() - 1);
    setDate(yesterday)
    var formatDate =(format ( date, 'yyyy-MM-dd'));
    var formatDay =format(date, 'EEEE');
    setFormatDay(formatDay)
    setFormatDate(formatDate)
    console.log(yesterday);
    console.log(formatDay);
    getConsultingHrs(docId , outsideDr , formatDate);
    // const dayOfWeek = format(date, 'EEEE');
    const dayOfWeek = format(yesterday, 'EEEE');  
    
  if (formatDay && dayOfWeek === weekOff ) {
    setTimeEmpty("❌ It's a Week Off");
  } else {
    setTimeEmpty("");
  }

  }

  const getConsultingHrs =(doctorId , otherDoctor , formatDate) =>{
    
    var baseUrl = "https://care.devakicare.com/admin/api_controller/getappointment_details?branch=1&doctor_id="+ doctorId +"&outside_dr=" + otherDoctor +"&appointment_date="+ formatDate +"&api=1" ;
    https://care.devakicare.com/admin/api_controller/getappointment_details?branch=1&doctor_id=49&outside_dr=1&api=1&appointment_date=2023-10-03
    axios.get(baseUrl).then((res) =>{
      var dta = res.data.data   
      var cH = JSON.parse( dta[0].consultation_hours );
      console.log(cH)
      var getConsultingHours = [] ;
      cH.forEach ((hours,index) => {
        var timings = hours.split(" - ");
        var startTime = timings[0];// 10:00
        var [hrs, mins] = startTime.split(":");
        var timeObj = new Date();
        timeObj.setHours(hrs);
        timeObj.setMinutes(mins);
        var formattedTime = format(timeObj, 'kk:mm aaa') ;

        var endTime = timings[1]; // 10:30
         [hrs, mins] = endTime.split(":");
         timeObj = new Date();
        timeObj.setHours(hrs);
        timeObj.setMinutes(mins);
        var formattedEndTime = format(timeObj, 'kk:mm aaa') ;

        var time = formattedTime + ' - ' + formattedEndTime ; //10:00 am - 10:30

        getConsultingHours.push({
          hours: time
        })
      })
      

      setConsulHr(getConsultingHours)
        console.log(getConsultingHours)
        // setTimeEmpty("❌ It's a Week Off");


    });
  }

  const onTimeChange = (event,hours) =>{
    setSelectedTime(hours);
     if(selectedTime == 0) {
      setTimeError("❌ Time Selection is required");
    }else{
      setTimeError("");
    }
  }
  // const changeNextDate = (event) =>{
  //   const tomorrow = new Date(date);
  //   tomorrow.setDate(tomorrow.getDate() + 1);
  //   setDate(tomorrow) 
  //   console.log(tomorrow);
  //   getConsultingHrs(docId , outsideDr);
  // }
  // const changePreDate = (event) =>{
  //   const yesterday = new Date(date);
  //   yesterday.setDate(yesterday.getDate() - 1);
  //   setDate(yesterday)
  //   console.log(yesterday);
  //   getConsultingHrs(docId , outsideDr);
  // }

  const handleButton = event => {
    console.log(event.target.value);
    setPatientType(event.target.value);
    // handlefields();
    if (event.target.value == 'old'){
    setShowDetails(false);
    setFirstName("");
    setNumber("");
    setDocId("");
    setDate(new Date())
    setConsulHr("");
    setTimeEmpty("");
    }else{
    setShowDetails(true);
    setFirstName("");
    setNumber("");
    setDocId("");
    setDate(new Date())
    setConsulHr("");
    setTimeEmpty("");   
    }
    setFirstName("");
    setNumber("");
    setDocId("");
    setDate(new Date())
    setConsulHr("");
    setTimeEmpty("");
  };
  // const handlefields = () =>{
    
  // }
  const onNameChange =(e) => {
    setFirstName (e.target.value);
    if(e.target.value.length == 0) {
      setError("❌ Patient name can not be empty");
    }else{
      setError("");
    }
  }
  const onNumberChange =(e) =>{
    setNumber (e.target.value);
    if(e.target.value.length == 0 || e.target.value.length > 10 || e.target.value.length < 10) {
      setNumError("❌ Please Enter a Valid Phone Number");
    }else{
      setNumError("");
    }

  }
  const onPIdChange =(e) => {
    setPatientId (e.target.value);
    if(e.target.value.length == 0) {
      setPError("❌ Patient ID name can not be empty");
    }else{
      setPError("");
    }
  }

   const handleSubmit = (e , doctorId , otherDoctor , formatDate ,bookTime) => {
    e.preventDefault();
    var isValidated = true;
      // setPost(response.data);
      //Name Validation
    if (firstName.length == 0) {
      setError("❌ Patient name can not be empty");
      isValidated = false;
    }else{
      setError("");
    }
    //Phone number validation
    var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
    if ((!pattern.test(number) || number.length < 10 ) ){
      setNumError("❌ Only accept number characters between 0-9"); 
      isValidated = false;
    } else {
      setNumError("");
    }
    // Doctor select validation
    if (docId == 0){
      setDocError("❌ Please Select a doctor");
      isValidated = false; 
    }else{
      setDocError("");
    }
    //week off
    // if (weekoffs == true){
    //   setTimeError("❌ It's a Week Off");
    // }

    //Time select validation
    if (selectedTime == null){
      setTimeError("❌ Please Select a Time");
      isValidated = false;
    }else{
      setTimeError("");
    }

    if (patient_type =="old" && patientId.length == 0 ) {
      setPError("❌ Patient ID name can not be empty");
      isValidated = false;
    }else{
      setPError("");
    }

    if(isValidated == true){
    var bodyFormData = new FormData();
    bodyFormData.append('patient_name', firstName);
    bodyFormData.append('mobileno', number);
    bodyFormData.append('doctor_id', docId);
    console.log(docId)
    var formatDate =(format ( date, 'yyyy-MM-dd'));
    console.log(formatDate)
    var formatTime = selectedTime.split(" - ");
    console.log(selectedTime)
    var bookTime = selectedTime;
    var bookTime1 = formatTime[0];// 10:00
    console.log(bookTime1)
    var formattedDT = formatDate +' '+ bookTime1;
    console.log(formattedDT);
    var baseURL = "https://care.devakicare.com/admin/api_controller/create_appointment?branch=1&doctor_id="+ docId +"&appointment_date="+ formatDate +"&appointment_time="+ bookTime +"&appointment_date_time="+ formattedDT +"&api=1" ;
    bodyFormData.append('appointment_date_time', formattedDT);
    bodyFormData.append('appointment_date', formatDate);
    bodyFormData.append('appointment_time', bookTime);
    bodyFormData.append('outside_dr', outsideDr );
    bodyFormData.append('patient_id', "");
    bodyFormData.append('gender', "");
    bodyFormData.append('email', "");


    axios({
      method: "post",
      url: baseURL,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },

    })

      .then(function (responsive) {
        //handle success
        console.log(responsive);
          setFirstName('')
          setNumber('')
          setDocId("")
          setConsulHr([])
          setTimeEmpty("")
          setPatientType("new")
          setOpen(false)
          confirmAlert({
            title: 'Success',
            message: responsive.data.message + '\n' + "Please make a note of your appointment number" + " " +responsive.data.data.appointment_no,
            buttons: [
              {
                label: 'Ok' 
              },
              
            ]
          });
          // alert (responsive.data.message + '\n' + "Please make a note of your appointment number" + " " +responsive.data.data.appointment_no);
         
      }) 
      .catch(function (responsive) {
        //handle error
        console.log(responsive);
      });
    }
  };
const close =(e) =>{
  setFirstName('')
  setError('')
  setNumber('')
  setNumError('')
  setDocId("")
  setDocError("")
  setConsulHr([])
  setTimeEmpty("")
  setPatientType("new")
  setDate(new Date())
  setOpen(false)
}

  const submitPatientDetails = (e) =>{
    e.preventDefault();

    if (patientId.length == 0 ) {
      setPError("❌ Patient ID name can not be empty");
    }else{
      setPError("");
    }
    // if(patientId == patient_unique_id){
    // console.log("OK success");
    //   }
    var baseURL = "https://care.devakicare.com/admin/api_controller/getPatientDetails?api=1&patient_unique_id=" + patientId;
  
  axios({
      method: "get",
      url: baseURL
    })

  .then(function (responsives) {
        //handle success
        if(patientId == responsives.data.data.patient_unique_id){
        console.log("OK success");

          }
        setPatId(responsives.data.data.patient_unique_id)
        setFirstName (responsives.data.data.patient_name);
        setError("");
        setNumber (responsives.data.data.mobileno);
        setNumError("");
        setShowDetails(true);
        console.log(responsives.data.data.mobileno);
        console.log(responsives.data.data.patient_name);
         //alert (responsive.data.message + '\n' + "Please make a note of your appointment number" + " " +responsive.data.data.appointment_no);
        
      }) 
      .catch(function (responsives) {
        //handle error
        console.log(responsives);
      });
  }
  


    //Phone Number Validation
    //  const phoneNum ="[0-9]{10}";
    //   if (!inputValues.phoneNo.match(phoneNum)) {
    //   errors.phoneNo = 'Please provide valid phone number';
    // } else {
    //   errors.phoneNo = '';
    // }
    //time validation
  //   if(!inputValues.time.trim()){
  //      errors.time = 'Please select timing';
  //   }
  //   else {
  //     errors.time = ' ';
  //   }
  //   //date validation
  //   if(!inputValues.date.trim()){
  //      errors.date = 'Please select date';
  //   }
  //   else {
  //     errors.date = ' ';
  //   }
  //   setValidation(errors);
  // };
  return (
    <div>
      <Button onClick={handleOpen}><span id="appointment-btn" className=" theme-btn btn-style-two btn-title hover">Appointment</span></Button>
      <Modal
        open={open}
        onClose={handleClose}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="model">
                    <div className="contact-form-two">
                        <div className="title-box">
                            <h4>Make an Appointment</h4>
                        </div>
                        <form id="contact-form">
                         {message && <div>{message}</div>}
                            <div className="row clearfix">
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="username">Patient Type</label><br></br>
                                    <input name="patient_type" type="radio" id="New" value="new" checked={patient_type === 'new'} onChange={handleButton} />
                                    <label htmlFor="New">New Patient</label>
                                    <input name="patient_type" type="radio" id="Old" value="old" checked={patient_type === 'old'} onChange={handleButton} />
                                    <label htmlFor="Old">Existing Patient</label>
                                </div>
                                {(patient_type == 'old') ?
                                (

                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">                                
                                <label htmlFor="username">Medical Record Number</label><br></br>
                                <div className="row">
                                <div className="col-lg-7 col-md-6 col-sm-12 form-group">
                                    <input type="text" name="username" placeholder="Medical Rec No" onChange={onPIdChange}/>        
                                    </div>
                                    <div className="col-lg-5 col-md-6 col-sm-12 form-group">
                                    <button type="submit" className="mt-2 theme-btn btn-style-one small" onClick={submitPatientDetails} >Get Details </button>
                                    </div>
                                    </div>
                                    <span className="error">{pError}</span>
                                </div>
                                ) :null 
                              }
                                
                              {showDetails === true &&
                                <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="username">Patient Name</label><br></br>
                                    <input type="text" name="fName"  id="fName" placeholder="Your Name" onChange={onNameChange} value={firstName} disabled={patient_type == "old" && firstName.length > 0  ? 'true' : ''}/>

                                    <span className="error">{error}</span>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="phone">Phone Number</label><br></br>
                                    <input type="text" name="phoneNo" placeholder="Your Phone" onChange={onNumberChange} value={number}/>
                                    <span className="error">{numError}</span>
                                </div>
                                 <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                 <label htmlFor="doctors" style={{"height" : "30px"}}>Select Doctor</label><br></br>
                                    <Select id="doctors" options={doctorList} onChange={doctoronChange}/>
                                    <span className="error">{docError}</span>
                                </div>
                                </div>
                                }
                               
                                {docId.length >0 && 
                                
                                <div className="row datedesign">
                                <div className="col-lg-2 col-md-2 col-sm-2 form-group">
                                    <button className="previous" type="button" onClick={changePreDate} disabled = {format(date, 'dd.MM.yyyy') == format(new Date(), 'dd.MM.yyyy') ? "true" : ""}>&#8249;</button>
                                </div>
                                 <div className="col-lg-8 col-md-8 col-sm-8 form-group">
                                <p style={{ color: '#1370b5', fontSize: 30}}>{format(date,'dd.MM.yyyy')}</p>
                                    <span style={{ color: '#1370b5', fontSize: 15 ,marginLeft : 30}}>{format(date, 'eeee')}</span>
                                </div>
                                  <div className="col-lg-2 col-md-2 col-sm-2 form-group">
                                 <button className="next" type="button" onClick={changeNextDate} disabled = {date.getDate()  == new Date().getDate() + 7  ? "true" : ""}>&#8250;</button>
                                </div>
                                </div>
                              }
 
                                {consulHr.length == ([]) &&
                                   <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                    <div className="row">
                                <span className="errormsg" >{timeEmpty}</span>
                                </div> 
                                </div>
                              }

                              
                             
                                                              
                              

                                {consulHr.length >0 && 
                                <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                  <label htmlFor="timing">Select Time</label><br></br>

                                    <div className="row"> 
                                      
                                      {consulHr.map(item => {
                                        return <button type="button" className={"btn col-lg-4" + (selectedTime == item['hours'] ? ' btn-primary ' : '') }  onClick={event => onTimeChange(event, item['hours']) }>{item['hours']}</button>;
                                      })}
                                      <span className="error">{timeError}</span>

                                    </div>
                                </div>
                                }

                                <div className="col-lg-12 col-md-12 col-sm-12 form-group mt-5">
                                    <button className="theme-btn btn-style-three small" type="submit" id="submit-button"  name="submit-form" onClick={handleSubmit}><span className="btn-title">Book Appointment</span></button>
                                    
                                </div>

                            </div>

                           
                        </form>
                    </div>
          </div>
        </Box>
      </Modal>
    </div>  
  );
}